.happy-meter-container {
  height: 100%;
  width: 100%;
  background: #00354C;
  border-bottom-right-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 10px;
  /* padding-right: 160px; */
  margin-right: 100px;
}

.progressBar {
  padding: 0;
  color: black;
}

.textContainer {
  color: white;
}

.happy-meter__title {
  align-self: flex-start;
  padding-top: 20px;
  margin-top: 20px;
  align-self: center;
}

.happy-meter__progressbar {
  /* flex-grow: 1; */
  width: "100px";
  align-self: center;
}

.happy-meter__result {
  align-self: center;
  justify-self: flex-end;
  margin-bottom: 20px;
}

.progress-bar__bar {
  transition-property: background-color;
  transition-duration: 300ms;
  border-radius: 0 0 15px 15px;
}

.progress-bar__bar--sad {
  background-color: #ef5350;
}

.progress-bar__bar--sad::after {
  content: url("./sentiment_dissatisfied_48px.svg");
  position: relative;
  top: -44px;
  left: -10px;
  background-color: #ef5350;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.6) 0 4px 4px;
}

.progress-bar__bar--netural {
  background-color: #ffa726;
}

.progress-bar__bar--netural::after {
  content: url("./sentiment_neutral_48px.svg");
  position: relative;
  top: -44px;
  left: -10px;
  background-color: #ffa726;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.6) 0 4px 4px;
}

.progress-bar__bar--smile {
  background-color: #9ccc65;
}

.progress-bar__bar--smile::after {
  content: url("./sentiment_satisfied_48px.svg");
  position: relative;
  top: -44px;
  left: -10px;
  background-color: #9ccc65;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.6) 0 4px 4px;
}

.progress-bar__bar--ecstatic {
  background-color: #66bb6a;
}

.progress-bar__bar--ecstatic::after {
  content: url("./sentiment_very_satisfied_48px.svg");
  position: relative;
  top: -44px;
  left: -10px;
  background-color: #66bb6a;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.6) 0 4px 4px;
}
